<template>
  <div class="privacy-settings">
    <div class="container">
      <article>
        <div class="privacy-settings__headings-wrapper">
          <base-sub-heading class="about-us__header-sub-heading" align="center">
            <span v-html="$t('privacySettings.subTitle')" />
          </base-sub-heading>
          <base-heading
            class="about-us__header-heading"
            align="center"
            bold="bold"
          >
            <span v-html="$t('privacySettings.title')" />
          </base-heading>
          <div class="privacy-settings__gradient" />
        </div>
        <base-table :columns="columns" :data="data">
          <template slot-scope="{ row }">
            <td>
              <base-checkbox
                type="square"
                :value="row.isActive"
                @input="handleChange"
              />
            </td>
            <td>
              {{ row.provider }}
            </td>
            <td>
              <p class="privacy-settings__table-description">
                {{ row.description }}
              </p>
            </td>
          </template>
        </base-table>
      </article>
      <a
        href="/privacy-policy-edunails.pdf"
        class="base-button base-button--default privacy-settings__link"
        target="'_blank'"
        rel="noopener noreferrer"
      >
        {{ $t("cookies.link") }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PrivacySettings',
  computed: {
    ...mapState({
      cookiesAgreements: (state) => !state.privacy.isModalOpen,
    }),
    columns() {
      return ['Active', 'Type', 'Description'].map((column, index) => ({
        key: index,
        label: column,
      }));
    },
    data() {
      return [
        {
          provider: 'Cookies',
          description:
            // eslint-disable-next-line max-len
            'Cookies are little text files that are sent when visiting an internet page and are stored in a user’s browser. In case the respective internet page is accessed once again, the user’s browser sends back the content of the cookies and, thus, allows for the recognition of the user. Certain cookies are automatically deleted upon ending the browser session (so-called session cookies), others are saved for a set time or permanently in the user’s browser and delete themselves thereafter (so-called temporary or persistent cookies).Cookies generally do not contain personal data, but instead only an online ID.',
        },
      ].map((item) => ({
        ...item,
        isActive: this.cookiesAgreements,
      }));
    },
  },
  methods: {
    handleChange(isActive) {
      this.$store.dispatch('privacy/setCookiesAgreement', isActive);
    },
  },
};
</script>
<style lang="scss" scoped src="./PrivacySettings.scss" />
